<template>
	<div class="-edit-main">
		
		<!-- 添加表单 -->
		<el-form class="-form -full" :model="form" :rules="rules" :ref="setting.formName" @submit.native="_formSubmit()" @submit.native.prevent>
			<el-form-item label="角色" prop="roleId">
				<div class="-w300">
					<el-select v-model="form.roleId" filterable><el-option v-for="(item, index) in options.RoleId" :key="index" :label="item.label" :value="item.id"></el-option></el-select>
				</div>
			</el-form-item>
			<el-form-item label="用户手机号" prop="mobile">
				<div class="-w300">
					<el-input v-model="form.mobile" placeholder="" />
				</div>
			</el-form-item>
			<el-form-item label="用户姓名" prop="name">
				<div class="-w300">
					<el-input v-model="form.name" placeholder="" />
				</div>
			</el-form-item>
			<el-form-item label="用户密码" prop="upwd">
				<div class="-w300">
					<el-input v-model="form.upwd" placeholder="" />
				</div>
			</el-form-item>
			<el-form-item label="备注" prop="remark">
				<el-input v-model="form.remark" :rows="3" type="textarea" placeholder="" />
			</el-form-item>
			<div class="-edit-footer">
				<el-button type="primary" native-type="submit" title="按下'Ctrl + Enter'键可快速提交">提 交</el-button>
				<el-button title="按下'Esc'可快速关闭" @click="closeDrawer()">取 消</el-button>
			</div>
		</el-form>
		
	</div>
	
</template>

<script>
import { reactive } from 'vue'
import _edit  from '@/assets/js/edit'

export default {
	props: [],
	emits: [ 'onClose' ],
	setup(props, context) {
		return {
			context,
			closeDrawer: ()=>{context.emit('onClose', 'add')},
			view: reactive({}),
			form: reactive({}),
			options: reactive({}),
			setting: reactive({
				moduleApi: '/System/User/',
				moduleName: '账号',
				addDetailApi: true,
			}),
			rules: {
				roleId: [
					{ required: true, message: '选择角色名称', trigger: 'blur' },
				],
				mobile: [
					{ required: true, message: '选择手机号码', trigger: 'blur' },
				],
				name: [
					{ required: true, message: '请输入用户姓名', trigger: 'blur' },
				],
				upwd: [
					{ required: true, message: '请输入用户密码', trigger: 'blur' },
				]
			},
		}
	},
	
	mounted: function(){
		this._load(this, _edit)
	},
	methods: {
		
	}
}
</script>
<style>

</style>