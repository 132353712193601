
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import Add from '@/views/system/user/add.vue'
import Edit from '@/views/system/user/edit.vue'

export default {
	components: { Add, Edit },
	setup(props, context) {
		return {
			options: reactive({}),
			setting: reactive({
				moduleName: '账号',
				moduleApi: '/System/User/',
				moduleType: 'full',
			}),
			list: reactive({data: []}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
		//切换状态
		changeState: function(id, state) {
			this._confirm('是否'+(state==1?'停用':'启用')+'当前账户吗？', ()=>{
				this._post('/System/User/changeState', { id: id, state: state==1?2:1}, (res)=>{
					this._msg('操作成功')
					this._listSearch()
				})
			})
		},
		
		//重置密码
		resetUpwd: function(id){
			this._confirm('是否重置当前用户密码为888888吗？', ()=>{
				this._post('/System/User/resetUpwd', { id: id}, (res)=>{
					this._msg('操作成功')
					this._listSearch()
				})
			})
		}
		
	}
}
